<template>
<span>
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid grid-list-md>
            <v-layout row wrap>

                <v-flex d-flex xs12>
                    <v-layout row wrap>

                        <v-flex xs12 sm6 md6 lg4 xl4>
                            <v-text-field v-model="storeshipping.stor_sh_nome" :rules="rules.stor_sh_nomeRules" label="Nome" outline required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="storeshipping.stor_sh_nick" label="Nick" autocomplete="off" outline></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="storeshipping.stor_sh_tipo" :rules="rules.stor_sh_tipoRules" label="Tipo" outline required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="storeshipping.stor_sh_valor" v-money="money" :rules="rules.stor_sh_valorRules" label="Valor" outline required></v-text-field>
                        </v-flex>
                        
                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="storeshipping.stor_sh_cep_origem" :rules="rules.stor_sh_cep_origemRules" label="CEP Origem" v-mask="'#####-###'" outline required></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <v-text-field v-model="storeshipping.stor_sh_mensagem" label="Mensagem" outline></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-switch v-model="storeshipping.stor_sh_is_correios" label="É Correios"></v-switch>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-switch v-model="storeshipping.stor_sh_is_gratis" label="É Grátis"></v-switch>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-switch v-model="storeshipping.stor_sh_is_lista_presente" label="É Lista de Casamento"></v-switch>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="storeshipping.stor_sh_gratis_maior_q" v-money="money" label="Grátis maior que R$" outline required></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md6 lg4 xl4>
                            <v-text-field v-model="storeshipping.stor_sh_valor_minimo_compra" v-money="money" label="Valor mínimo de compra" outline required></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-switch v-model="storeshipping.stor_sh_status" label="Status"></v-switch>
                        </v-flex>

                        <v-flex xs12>
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                {{btnLabel}}
                            </v-btn>
                        </v-flex>

                    </v-layout>
                </v-flex>

            </v-layout>
        </v-container>

    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    name: "FormStoreShippingComponent",
    props: {
        storeshipping: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    stor_sh_id: '',
                    stor_sh_nome: '',
                    stor_sh_nick: '',
                    stor_sh_tipo: '',
                    stor_sh_mensagem: '',
                    stor_sh_valor: '',
                    stor_sh_is_correios: false,
                    stor_sh_is_gratis: false,
                    stor_sh_gratis_maior_q: '',
                    stor_sh_is_lista_presente: false,
                    stor_sh_cep_origem: '',
                    stor_sh_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {
       
    },
    computed: {
        
    },
    data() {
        return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            valid: true,
            rules: {
                stor_sh_nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 200) || 'Nome precisa ter ate 200 Caracteres'
                ],
                stor_sh_tipoRules: [
                    v => !!v || 'Campo Tipo é obrigatório',
                ],
                stor_sh_valorRules: [
                    v => !!v || 'Campo Valor é obrigatório',
                ],
                stor_sh_cep_origem: [
                    v => !!v || 'Campo CEP Origem é obrigatório',
                    v => (v && v.length >= 8) || 'Minímo de 8 characters',
                ],
            },
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            const action = this.update ? 'updateStoreShipping' : 'storeStoreShipping';
            const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

            this.$store.dispatch(action, this.storeshipping)
                .then(() => {
                    this.$swal({
                        position: "center",
                        type: "success",
                        title: msg,
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.$router.push({
                        name: 'store-shippings'
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.errors = error.response.data.errors
                })
        },

        validate() {
            if (this.$refs.form.validate()) {
                this.snackbar = true
            }
        },
    },
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}
</style>
